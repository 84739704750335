.title {
  font-size: 16px;
  line-height: 24px;
  color: #5182ff;
}
.requiredMark {
  color: #ff5a26;
}
.submit-btn {
  width: '300px';
  height: '50px';
  background-color: '#5182FF';
  font-size: '24px';
  color: 'white';
  font-weight: 'bold';
  border-radius: '30px';
  box-shadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)';
}
